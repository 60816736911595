export const FormFieldRules = {
    required:[
        v => v==null || v==='' ? 'Поле обязательно' : true
    ],
    email: [
        v => (v || '').indexOf(' ') < 0 || 'Не должно быть пробелов',
        v => /.+@.+/.test(v) || 'Не соответсвует формату email'
    ],
    pass: [
        v => (v || '').indexOf(' ') < 0 || 'Не должно быть пробелов',
        v => v.length === 0 || v.length >= 4 || 'Пароль должен быть не меньше 4 символов',
        v => v.length < 32 || 'Пароль должен быть не больше 32 символов'
    ]
}