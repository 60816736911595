<template>
  <div class="pa-4">
    <div ref="box">
      <v-row
          class="rowSmall"
          align-content="start"
      >
        <v-col v-if="typeof $slots.tabs!=='undefined'" ref="tabs" cols="12">
          <v-sheet elevation="2" rounded class="position-relative overflow-auto">
            <slot name="tabs"/>
          </v-sheet>
        </v-col>

        <v-col v-if="typeof $slots.nav!=='undefined'" cols="auto">
          <v-sheet
              min-width="310px"
              max-width="310px"
              elevation="2" rounded class="position-relative overflow-auto">
            <toolbar-view :title="typeof navTitle==='undefined' ? 'Меню' : navTitle" :loading="navLoading">
              <template v-slot:barActions>
                <slot name="navBarActions"/>
              </template>
            </toolbar-view>
            <div :style="(typeof navScroll==='undefined' || navScroll ? 'overflow:auto;' : '')+'height:'+navHeight+'px'">
              <slot name="nav"/>
            </div>
          </v-sheet>
        </v-col>
        <v-col :style="'max-width:'+contentWidth+'px'">
          <v-row no-gutters>
            <v-col v-if="typeof $slots.widgets!=='undefined'" cols="12" class="mb-4">
              <slot name="widgets"/>
            </v-col>
            <v-col ref="content">
              <v-sheet v-if="typeof contentRemoveCard==='undefined'" elevation="2" rounded class="position-relative">
                <toolbar-view v-if="typeof contentTitle!=='undefined'" ref="contentTitle" :title="contentTitle" :loading="contentLoading">
                  <template v-slot:barActions>
                    <slot name="navBarActions"/>
                  </template>
                </toolbar-view>
                <div :style="'overflow:auto;height:'+contentHeight+'px'">
                  <slot name="content" v-bind:height="contentHeight"/>
                </div>
              </v-sheet>
              <v-sheet v-else class="position-relative" key="2" color="transparent">
                <toolbar-view v-if="typeof contentTitle!=='undefined'" ref="contentTitle" :title="contentTitle" :loading="contentLoading">
                  <template v-slot:barActions>
                    <slot name="navBarActions"/>
                  </template>
                </toolbar-view>
                <div :style="'overflow:auto;height:'+contentHeight+'px'">
                  <slot name="content" v-bind:height="contentHeight"/>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
        <slot name="footer"/>
      </v-row>
    </div>

  </div>

</template>

<script>
import ToolbarView from "@/components/ToolbarView";
export default {
  components: {ToolbarView},
  props: [
    'navTitle',
    'navScroll',
    'navLoading',
    'contentTitle',
    'contentRemoveCard',
    'contentLoading',
  ],
  data: ()=>({
    navHeight:0,
    contentWidth:0,
    contentHeight:0,
  }),
  mounted(){
    this.resize();
    setTimeout(()=>{this.resize();},250);
    setTimeout(()=>{this.resize();},500);
    setTimeout(()=>{this.resize();},1000);
    setTimeout(()=>{this.resize();},2000);
    window.addEventListener('resize', ()=>{
      this.resize();
    }, true);
  },
  watch:{
    navTitle(){
      this.resize();
    },
    navLoading(){
      this.resize();
    },
    contentTitle(){
      this.resize();
    },
    contentLoading(){
      this.resize();
    },
  },
  methods:{
    resize(){
      this.navHeight = window.innerHeight-16-8-48;
      this.contentHeight = window.innerHeight-16;

      let box = this.$refs.box;
      if(typeof box!=='undefined') {
        let boxPos = box.getBoundingClientRect();
        this.navHeight -= boxPos.top;
        this.contentWidth = boxPos.width;
      }

      let tabs = this.$refs.tabs;
      if(typeof tabs!=='undefined') {
        let tabsPos = tabs.getBoundingClientRect();
        this.navHeight -= tabsPos.height;
      }

      if(!this.$tools.isEmpty(this.navTitle))
        this.contentWidth -= 310;

      if(!this.$tools.isEmpty(this.contentTitle))
        this.contentHeight -= 48;

      let content = this.$refs.content;
      if(typeof content!=='undefined'){
        let contentPos = content.getBoundingClientRect();
        this.contentHeight -= contentPos.top;
      }
    }
  }
}
</script>