<template>
  <div>
    <Reports/>
  </div>
</template>

<script>
import Reports from "@/views/Analytic/Reports.vue";

export default {
  components: {
    Reports,
  }
}
</script>