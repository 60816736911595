<template>
  <layout-view
      navTitle="Отчеты"
      :navLoading="reportsState.isLoading"
  >
    <template v-slot:nav>
      <v-list dense>
        <v-list-item-group mandatory v-model="scriptIndex">
          <v-list-item v-for="(v, i) in scripts" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{v.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:content>
      <div v-if="reportsState.isSuccess || scripts.length>0">
        <toolbar-view :title="scripts[scriptIndex].name"/>
        <div class="px-4">
          <v-row class="rowSmall">
            <v-col cols="auto">
              <v-sheet width="310">
                <v-sheet outlined rounded class="mb-4">
                  <toolbar-view title="Сформировать"/>
                  <v-form ref="form" class="px-4 pb-4">
                    <div v-for="(v, i) in scripts[scriptIndex].params" :key="i">
                      <date v-if="v.type==='date'" :name="v.name" :value.sync="v.value" class="mb-4 full-width" />
                    </div>
                    <v-btn depressed block small color="primary" @click="reportAction()" :loading="actionState.isLoading">
                      <v-icon class="mr-2" small>mdi-creation</v-icon>Отправить
                    </v-btn>
                  </v-form>
                </v-sheet>
                <v-sheet outlined rounded>
                  <toolbar-view title="Последние данные"/>
                  <div class="px-4 pb-4">
                    <div v-if="scripts[scriptIndex].result===null">
                      <alert icon="mdi-information-outline">Отчет еще не был сформирован</alert>
                    </div>
                    <div v-else>
                      <div class="body-2">
                        Сформировано
                        {{$tools.dateTimeFormat(scripts[scriptIndex].result.date, 'DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')}}<br/>
                        c параметрами:
                      </div>
                      <div v-for="(v, i) in scripts[scriptIndex].params" :key="i" class="body-2">
                        <v-row no-gutters>
                          <v-col>{{v.name}}:</v-col>
                          <v-col>{{v.lastValue}}</v-col>
                        </v-row>
                      </div>
                      <v-row class="rowSmall mt-2">
                        <v-col>
                          <v-btn small block depressed color="primary" @click="previewAction()" :disabled="!scripts[scriptIndex].previewAvailable" :loading="previewState.isLoading">
                            <v-icon class="mr-2" small>mdi-eye</v-icon>Просмотр
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn small block depressed color="primary" @click="$tools.openUrl(scripts[scriptIndex].result.url)">
                            <v-icon class="mr-2" small>mdi-download</v-icon>Скачать
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-sheet>
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet outlined rounded>
                <toolbar-view title="Справка"/>
                <div class="px-4 pb-4">
                  <div v-if="$tools.isEmpty(scripts[scriptIndex].faq)">
                    <alert icon="mdi-information-outline">Не доступно</alert>
                  </div>
                  <div v-else v-html="$tools.nl2br(scripts[scriptIndex].faq)"></div>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-dialog
          v-model="previewState.isModalOpen"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
      >
        <v-card class="grey lighten-4" v-if="reportsState.isSuccess">
          <v-toolbar
              dark
              :color="!$vuetify.theme.dark ? 'rgb(2, 146, 77)' : 'rgb(40 40 40)'"
              :max-height="64+42"
              :extension-height="48"
              style="z-index: 1"
          >
            <v-btn icon dark @click="previewState.modalClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{scripts[scriptIndex].name}}
              <div class="body-2 mt-n1">
                Сформировано
                {{$tools.dateTimeFormat(scripts[scriptIndex].result.date, 'DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')}}
                c параметрами:
                <span v-for="(v, i) in scripts[scriptIndex].params" :key="i">
                  {{v.name}}:{{v.lastValue}}
                </span>
              </div>
            </v-toolbar-title>
            <template v-slot:extension>
              <v-tabs v-if="previewState.modalData!==null" v-model="previewState.modalData1" background-color="transparent" class="mb-2">
                <v-tab v-for="(v, i) in previewState.modalData.length" :key="i">{{previewState.modalData[i].name}}</v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <div v-if="previewState.modalData!==null">
            <div v-for="(v, i) in previewState.modalData.length" :key="i">
              <div v-if="previewState.modalData1===i">
                <v-simple-table dense fixed-header :height="$tools.pageHeight()-20">
                  <thead>
                    <tr>
                      <th v-for="(v, i) in previewState.modalData[i].headers" :key="i">{{v}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in previewState.modalData[i].items" :key="i">
                      <td v-for="(v1, i) in v" :key="i" class="body-2 text-no-wrap" style="user-select: text">
                        {{v1}}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </template>
  </layout-view>
</template>
<script>
import LayoutView from "@/components/LayoutView.vue";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import ToolbarView from "@/components/ToolbarView.vue";
import {FormFieldRules} from "@/plugins/formFieldRules";
import Date from "@/componentsV2/base/Date.vue";
import Alert from "../../componentsV2/base/Alert.vue";

export default {
  components: {Alert, Date, ToolbarView, LayoutView},
  data: () => ({
    reportsState: new State(),
    actionState: new State(),
    scripts: [],
    scriptIndex: 0,
    formFieldRules: FormFieldRules,
    timer:null,
    previewState: new State(),
  }),
  mounted(){
    this.load();
    this.timer = setInterval(()=>{
      this.$tools.pageVisibleCallback(()=> {
        this.load()
      }, ()=>{
        this.$store.commit('alertWarning', 'Данные могли устареть. Обновите страницу или подождите 15 сек.');
      })
    },15*1000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    load(){
      this.reportsState.stateLoading();
      axios.get(Api.host + '/analytic/report/')
          .then((r) => {
            let arr = [];
            if(this.scripts.length>0){
              this.scripts[this.scriptIndex].params.forEach(v=>{
                arr.push(this.$tools.isEmpty(v.value) ? null : v.value);
              });
            }
            r.data.forEach(v=>{
              v.params.forEach(v1=>{
                if(v1.type==='date')
                  v1.value = this.$tools.dateTimeFormat(v1.value, 'DD.MM.YYYY', 'YYYY-MM-DD HH:mm:ss');
              })
            })
            r.data.forEach(v=>{
              v.params.forEach(v1=>{
                v1.lastValue = v1.value;
              })
            })
            this.scripts = r.data;
            arr.forEach((v,k)=>{
              this.scripts[this.scriptIndex].params[k].value = v;
            });
            this.reportsState.stateSuccess();
          })
          .catch(e => {
            Api.responseError(e)
            this.reportsState.stateError();
          });
    },
    reportAction(){
      if (this.$refs.form.validate()){
        this.actionState.stateLoading();
        var data = [];
        this.scripts[this.scriptIndex].params.forEach(v=>{
          data.push({
            id:v.id,
            value:v.value,
          })
        });
        axios.post(Api.host + '/analytic/report/'+this.scripts[this.scriptIndex].id+"/", data)
        .then(() =>{
          this.actionState.stateSuccess();
          this.load();
          this.$store.commit('alertSuccess', 'Формирование отчета запущено');
        })
        .catch(e =>{
          this.actionState.stateError(e);
        });
      }
    },
    previewAction(){
      this.previewState.stateLoading();
      axios.get(Api.host + '/analytic/report/'+this.scripts[this.scriptIndex].id+"/preview/")
          .then((r) =>{
            this.previewState.stateSuccess();
            this.previewState.modalOpen(r.data, 0);
          })
          .catch(() =>{
            this.previewState.stateError();
          });
    }
  }
}
</script>