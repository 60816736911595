<template>
  <div>
    <v-progress-linear v-show="loading" indeterminate class="position-absolute"/>
    <v-toolbar v-if="typeof title!=='undefined'" class="bar" dense elevation="0" color="transparent" max-height="48px">
      <v-toolbar-title class="body-2 font-weight-medium">{{title}}</v-toolbar-title>
      <v-toolbar-title v-if="!$tools.isEmpty(subtitle)" class="body-2 font-weight-medium muted1 mx-2">({{subtitle}})</v-toolbar-title>
      <v-spacer/>
      <slot name="barActions"/>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: [
    'loading',
    'title',
    'subtitle'
  ]
}
</script>
